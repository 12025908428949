import React from "react"
import { Alert as ReactStrapAlert, AlertProps } from "reactstrap"
import { Colors } from "../../theme/types"

interface Props extends AlertProps {
  color?: Colors
}

export const Alert: React.FC<Props> = ({ color = "info", ...rest }) => {
  return <ReactStrapAlert color={color} {...rest} />
}

export default Alert
