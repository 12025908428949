import CustomLayout from "./wrapPageElement"

export const wrapPageElement = CustomLayout

export const shouldUpdateScroll = () => {
  // https://www.gatsbyjs.org/docs/browser-apis/#shouldUpdateScroll
  // scrolling up both widow and body, due to css body height 100% required by intersectionObserver polyfill
  window.scrollTo(0, 0)
  document.getElementsByTagName("body")[0].scroll(0, 0)
  return false
}
