import React from "react"
import { Nav, NavItem, Container, Row, Col } from "src/ui"
import Logo from "src/ui/img/logo.svg"
import { slide as ReactBurgerMenu } from "react-burger-menu"
import { PageContext } from "ExtendiApp"
import { ActiveLanguagesKeys, LanguageSettings } from "i18n"
import LocalizedLink from "../localizedLink"
import { getMenuLanguages } from "./utils"
import { Link } from "gatsby"
import { CrossMenuButton } from "./CrossMenuButton"
import { SocialSvgIcons } from "./SocialSvgIcons"

interface Props {
  menuIsOpen: boolean
  setMenuIsOpen: (state: boolean) => void
  allPages: PageContext[]
  intlPageVersions: PageContext[]
  locale: ActiveLanguagesKeys
  languages: LanguageSettings
}

const sortPages = (a: PageContext, b: PageContext) => (a.menuOrder > b.menuOrder ? 1 : -1)

const getTripAdvisorUrl = (locale: PageContext["locale"]) => {
  const languageParameter = locale === "en" ? "com" : locale.toLowerCase()
  const baseUrl = `https://www.tripadvisor.${languageParameter}/Hotel_Review-g1184174-d1520796-Reviews-Residence_Hotel_Paradiso-Villa_Rosa_di_Martinsicuro_Abruzzo.html`
  return baseUrl
}

export const Menu: React.FC<Props> = ({ allPages, menuIsOpen, setMenuIsOpen, locale, intlPageVersions, languages }) => {
  // Filtering all pages with active locale and with a slug (so no home and null content)
  const localePages = allPages
    .filter(page => page && page.locale === locale)
    .filter(page => Boolean(page.slug))
    .sort(sortPages)
  const rootLevel = localePages
    .filter(page => !page.notShowInMenu)
    .filter(page => page.parentId === null)
    .sort(sortPages)

  return (
    <div
      className={menuIsOpen ? "menu-is-open" : "menu-is-closed"}
      style={{
        pointerEvents: menuIsOpen ? "initial" : "none",
      }}
      onClick={() => setMenuIsOpen(false)}
    >
      <ReactBurgerMenu
        right
        isOpen={menuIsOpen}
        onStateChange={({ isOpen }) => setMenuIsOpen(isOpen)}
        customCrossIcon={false}
        noOverlay
        // disableOverlayClick
        htmlClassName={"menu-open"}
      >
        <div>
          {/* Logo */}
          <LocalizedLink to={"/"} className="d-none d-md-block menu-logo">
            <img alt="Hotel Paradiso" src={Logo} />
          </LocalizedLink>

          {/* Button close */}
          <CrossMenuButton onClick={() => setMenuIsOpen(false)} />

          {/* List items: autogenerated from markdown files */}
          <Container>
            <Row>
              {rootLevel.map(item => {
                return (
                  <Col md="6" lg="4" className="mb-4 mb-md-5" key={item.pageId}>
                    {/* Root level (example: Hotel and Residence ) */}
                    <LocalizedLink
                      to={item.slug}
                      className="menu-level-1"
                      onClick={e => {
                        // preventing navigation to humans
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      {item.title}
                    </LocalizedLink>

                    {/* Children (subpages) */}
                    <ul className="list-unstyled">
                      {localePages
                        .filter(page => page.parentId === item.pageId)
                        .map(page => (
                          <li key={page.pageId}>
                            <LocalizedLink to={page.slug} className="menu-level-2">
                              {page.title}
                            </LocalizedLink>
                          </li>
                        ))}
                    </ul>
                  </Col>
                )
              })}
            </Row>

            {/* MENU FOOTER */}
            <Row>
              <Col>
                <div className="menu-footer d-flex justify-content-between py-3">
                  {/* Languges */}
                  <Nav>
                    {Object.keys(languages).map(lang => {
                      const { langKey, langMenuLink } = getMenuLanguages(lang, intlPageVersions)
                      return (
                        <NavItem key={langKey}>
                          <Link
                            to={langMenuLink}
                            hrefLang={langKey}
                            className={`nav-link ${langKey === locale ? " nav-link-active" : ""}`}
                            onClick={e => {
                              e.stopPropagation()
                            }}
                          >
                            {langKey.toUpperCase()}
                          </Link>
                        </NavItem>
                      )
                    })}
                  </Nav>

                  {/* Social icons */}
                  <div>
                    <a href="https://www.facebook.com/residencehotelparadiso" className="mr-2" target="noopener">
                      <SocialSvgIcons iconName="facebook" color="#fff" />
                    </a>
                    <a href="https://www.instagram.com/hotelresidenceparadiso" className="mr-2" target="noopener">
                      <SocialSvgIcons iconName="instagram" color="#fff" />
                    </a>
                    <a href={getTripAdvisorUrl(locale)} target="noopener">
                      <SocialSvgIcons iconName="tripadvisor" color="#fff" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactBurgerMenu>
    </div>
  )
}
