import React, { Fragment } from "react"
import { Container, Row, Col, Nav, NavItem, FormGroup, Input, Button } from "src/ui"
import { Link } from "gatsby"
import useTranslations from "../useTranslations"
import { getLocalizedSubmitUrl } from "src/components/Navigation/utils"
import { LocaleContext } from "../layout"
import InViewMonitor from "react-inview-monitor"
import TelIcon from "src/ui/img/ico_tel_white.svg"
import MailIcon from "src/ui/img/ico_mail_white.svg"
import FacebookIcon from "src/ui/img/ico_facebook_white.svg"
import InstagramIcon from "src/ui/img/ico_instagram_white.svg"
import TripAdvisorIcon from "src/ui/img/ico_tripadvisor_white.svg"
import Michelin from "src/ui/img/logo_michelin.png"
import Tci from "src/ui/img/logo_tci.png"
import Holiday from "src/ui/img/logo_holiday.png"
import useInternalLinks from "../useInternalLinks"
import CookieConsent from "react-cookie-consent"
import { InternalLink } from "../InternalLink"
import { PageContext } from "ExtendiApp"
import { sendTagManagerEvent } from "src/utils/googleTagManager"
import ImagesBlock from "../ImagesBlock"

export const Footer: React.FC = () => {
  const { locale } = React.useContext(LocaleContext)
  const legal_notes = useTranslations("legal_notes")
  const privacy = useTranslations("privacy")
  const cookies = useTranslations("cookies")
  const get_a_quote = useTranslations("get_a_quote")
  const get_form = useTranslations("get_form")
  const spotted = useTranslations("spotted_on")
  const submitText = useTranslations("submit_button_text")

  const linkCookiesPage = useInternalLinks("cookies")
  const linkPrivacyPage = useInternalLinks("privacy")

  const cookie_content_text = useTranslations("cookie_content_text")
  const cookies_accept = useTranslations("cookies_accept")

  const getTripAdvisorUrl = (locale: PageContext["locale"]) => {
    const languageParameter = locale === "en" ? "com" : locale.toLowerCase()
    const baseUrl = `https://www.tripadvisor.${languageParameter}/Hotel_Review-g1184174-d1520796-Reviews-Residence_Hotel_Paradiso-Villa_Rosa_di_Martinsicuro_Abruzzo.html`
    return baseUrl
  }

  return (
    <Fragment>
      <footer>
        <ImagesBlock />
        <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
          <div className="d-flex d-lg-none">
            <a
              href="tel:+390861713888"
              className="py-4 flex-fill text-center bg-warning"
              target="_blank"
              rel="noopener"
              onClick={() => {
                sendTagManagerEvent({
                  event: "editaTelefono",
                  eventCategory: "Telefono",
                  eventAction: "Click",
                  eventLabel: "+390861713888",
                  eventValue: "7",
                  eventNhi: "FALSE",
                  eventFacebook: "Telefono Click",
                })
              }}
            >
              <img alt="" src={TelIcon} />
            </a>
            <a
              href="mailto:info@hotelparadiso.it"
              className="py-4 flex-fill text-center bg-marine"
              target="_blank"
              rel="noopener"
              onClick={() => {
                sendTagManagerEvent({
                  event: "editaEmail",
                  eventCategory: "Email",
                  eventAction: "Click",
                  eventLabel: "info@hotelparadiso.it",
                  eventValue: "7",
                  eventNhi: "FALSE",
                  eventFacebook: "Email Click",
                })
              }}
            >
              <img alt="" src={MailIcon} />
            </a>
            <a
              href="https://www.facebook.com/residencehotelparadiso"
              className="py-4 flex-fill text-center bg-ocean"
              target="_blank"
              rel="noopener"
            >
              <img alt="" src={FacebookIcon} />
            </a>
            <a
              href="https://www.instagram.com/hotelresidenceparadiso"
              className="py-4 flex-fill text-center bg-crimson"
              target="_blank"
              rel="noopener"
            >
              <img alt="" src={InstagramIcon} />
            </a>
            <a
              href={getTripAdvisorUrl(locale)}
              className="py-4 flex-fill text-center bg-success"
              target="_blank"
              rel="noopener"
            >
              <img alt="" src={TripAdvisorIcon} />
            </a>
          </div>

          <section className="section section-half bg-info bg-waves d-none d-lg-block">
            <Container>
              <div className="d-flex flex-row justify-content-center">
                <a
                  href={getLocalizedSubmitUrl(locale)}
                  className="btn btn-lg btn-secondary m-3 shadow-lg"
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    sendTagManagerEvent({
                      event: "editaBooking",
                      eventCategory: "Booking",
                      eventAction: "Click",
                      eventLabel: window?.location.href,
                      eventValue: "1",
                      eventNhi: "FALSE",
                      eventFacebook: "Booking Click",
                    })
                  }}
                >
                  {get_a_quote}
                </a>
                <InternalLink pageId="richieste-prenotazione/richiesta" className="btn btn-lg btn-outline-light m-3">
                  {get_form}
                </InternalLink>
              </div>
            </Container>
          </section>
        </InViewMonitor>
        <div className="footer-bottom section">
          <Container>
            <Row>
              <Col lg="3" className="text-center text-lg-left">
                <address>
                  <p className="small">
                    <strong>
                      Residence Hotel Paradiso<sup>***</sup> <br />
                      Fam. Raschiatore
                    </strong>
                    <br />
                    Via Ugo La Malfa, 14
                    <br />
                    64014 Villa Rosa di Martinsicuro (TE)
                    <br />
                    Abruzzo - {useTranslations("italy")}
                    <br />
                    P.IVA 00251730677
                  </p>
                </address>
              </Col>
              <Col lg="6" className="text-center">
                <div className="d-none d-lg-block text-nowrap">
                  <p className="mb-3">
                    <a
                      href="tel:+390861713888"
                      className="display-4"
                      onClick={() => {
                        sendTagManagerEvent({
                          event: "editaTelefono",
                          eventCategory: "Telefono",
                          eventAction: "Click",
                          eventLabel: "+390861713888",
                          eventValue: "7",
                          eventNhi: "FALSE",
                          eventFacebook: "Telefono Click",
                        })
                      }}
                    >
                      T. +39 0861 713888
                    </a>
                  </p>
                  <p>
                    <a
                      href="mailto:info@hotelparadiso.it"
                      className="display-4"
                      onClick={() => {
                        sendTagManagerEvent({
                          event: "editaEmail",
                          eventCategory: "Email",
                          eventAction: "Click",
                          eventLabel: "info@hotelparadiso.it",
                          eventValue: "7",
                          eventNhi: "FALSE",
                          eventFacebook: "Email Click",
                        })
                      }}
                    >
                      info@hotelparadiso.it
                    </a>
                  </p>
                </div>
                <Nav className="justify-content-center py-2">
                  <NavItem>
                    <Link to={linkPrivacyPage} className="nav-link">
                      {privacy}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={linkCookiesPage} className="nav-link">
                      {cookies}
                    </Link>
                  </NavItem>
                </Nav>
                <small>P. IVA 00251730677</small>
              </Col>
              <Col lg="3" className="d-none d-lg-block">
                <div className="text-right d-flex align-items-end flex-column">
                  <div className="credits"></div>
                  <div className="socials d-flex">
                    <a
                      href="https://www.facebook.com/residencehotelparadiso"
                      className="facebook mr-3"
                      target="noopener"
                    ></a>
                    <a
                      href="https://www.instagram.com/hotelresidenceparadiso"
                      className="instagram  mr-3"
                      target="noopener"
                    ></a>
                    <a href={getTripAdvisorUrl(locale)} className="tripadvisor" target="noopener"></a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-none d-sm-block">
              <div className="spotted text-center mt-4">
                <h6 className="text-light small text-uppercase mb-4">{spotted}</h6>
                <img alt="" src={Michelin} />
                <img alt="" src={Tci} />
                <img alt="" src={Holiday} />
              </div>
            </div>
          </Container>
        </div>
        <div className="section py-4 bg-light d-none d-md-block">
          <Container>
            <Row>
              <h3 className="py-3">Newsletter</h3>
              <form
                className="w-100"
                method="post"
                action="https://e7g9i.emailsp.com/frontend/subscribe.aspx"
                onSubmit={() => {
                  sendTagManagerEvent({
                    event: "editaNewsletter",
                    eventCategory: "Newsletter",
                    eventAction: "Submit",
                    eventLabel: window?.location.href,
                    eventValue: "5",
                    eventNhi: "FALSE",
                    eventFacebook: "Newsletter Submit",
                  })
                }}
              >
                <Row form>
                  <Col sm="9">
                    <FormGroup>
                      <input type="hidden" name="list" value="1" />
                      <input type="hidden" name="group" value="24" />
                      <Input bsSize="lg" type="email" name="email" placeholder="E-mail" />
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <Button color="success" block>
                      {submitText}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Row>
          </Container>
        </div>
      </footer>
      <CookieConsent
        disableStyles
        buttonClasses="btn btn-secondary btn-xs"
        containerClasses="alert col-lg-12 mb-0"
        buttonText={cookies_accept}
        contentClasses="mb-2 mb-sm-0"
      >
        {cookie_content_text} <Link to={linkCookiesPage}>Cookie Policy</Link>.
      </CookieConsent>
    </Fragment>
  )
}
