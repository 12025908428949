import React from "react"
const ImagesBlock: React.FC = () => {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-5">
      <div className="p-3">
        <img
          width={120}
          height={120}
          src={require(`src/assets/imgs/blue-flag.png`)}
          alt={"logo blue flag"}
          className="img-fluid"
        />
      </div>
      <div className="p-3">
        <img
          width={110}
          height={110}
          src={require(`src/assets/imgs/comuni-ciclabili.png`)}
          alt={"logo comuni ciclabili"}
          className="img-fluid"
        />
      </div>
      <div className="p-3">
        <img
          width={240}
          height={180}
          src={require(`src/assets/imgs/vivi-Martinsicuro.svg`)}
          alt={"logo vivi martinsicuro"}
          className="img-fluid"
        />
      </div>
    </div>
  )
}
export default ImagesBlock
