import React, { useState, useEffect } from "react"
import { useScrollYPosition } from "src/utils/useScrollYPosition"

export const ScrollToTop = () => {
  const scrollTop = () => {
    document.getElementsByTagName("body")[0].scroll({ top: 0, behavior: "smooth" })
  }

  const [isVisible, setIsVisible] = useState(false)
  const scrollYposition = useScrollYPosition(500)

  useEffect(() => {
    setIsVisible(scrollYposition > 700)
  }, [scrollYposition])

  return (
    <div
      className={`d-lg-none scroll-top-container fade ${isVisible ? "show" : ""}`}
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        zIndex: 99999,
      }}
    >
      <button className="border-0 rounded-circle bg-white p-0 scroll-top-button" onClick={scrollTop}>
        <svg width="50" viewBox="0 0 337.085 337.086" xmlSpace="preserve">
          <path
            fill="var(--success)"
            d="M168.54,0C75.619,0,0.012,75.609,0.012,168.543c0,92.93,75.606,168.543,168.528,168.543
		c92.933,0,168.534-75.613,168.534-168.543C337.074,75.609,261.473,0,168.54,0z M271.405,224.327
		c-2.407,2.402-5.561,3.615-8.713,3.615c-3.158,0-6.311-1.213-8.719-3.615L168.54,138.9l-85.424,85.427
		c-4.816,4.815-12.631,4.815-17.447,0c-4.819-4.815-4.819-12.622,0-17.438l94.152-94.141c4.822-4.815,12.613-4.815,17.426,0
    l94.152,94.141C276.221,211.705,276.221,219.512,271.405,224.327z"
          />
        </svg>
      </button>
    </div>
  )
}
