import React from "react"
import { Link } from "gatsby"
import { LocaleContext } from "./layout"
import locales from "../../config/i18n"

interface Props {
  to: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

// Use the globally available context to choose the right path
const LocalizedLink: React.FC<Props> = ({ to, ...props }) => {
  const context = React.useContext(LocaleContext)
  const locale = context && context.locale
  const isIndex = to === `/`

  if (!locale) return null

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  // const path = locales[locale].default
  //   ? to
  //   : `${locales[locale].path}${isIndex ? `` : `${to}`}`
  const path =
    to === 'richieste-e-prenotazione/listino-prezzi' ?
    'https://secure.iperbooking.net/be/it/residence-hotel-paradiso-teramo/?_ga=2.115266759.1354106684.1656416869-914812399.1655378820' :
    to === 'reservation/tarifs' ? 
    'https://secure.iperbooking.net/be/fr/residence-hotel-paradiso-teramo/?_ga=2.120452168.1354106684.1656416869-914812399.1655378820' :
    to === 'enquiries/pricelist' ?
    'https://secure.iperbooking.net/be/UK/residence-hotel-paradiso-teramo/?_ga=2.110091333.1354106684.1656416869-914812399.1655378820' :
    to === 'anfragen-und-buchen/preisliste' ?
    'https://secure.iperbooking.net/be/de/residence-hotel-paradiso-teramo/?_ga=2.41132002.1354106684.1656416869-914812399.1655378820' :
    to.charAt(0) !== "/"
      ? `/${locales[locale].path}/${isIndex ? `` : `${to}`}`
      : `/${locales[locale].path}${isIndex ? `` : `${to}`}`

  return <Link {...props} to={path} />
}

export default LocalizedLink
