import React from "react"
import { Button as ReactStrapButton, ButtonProps } from "reactstrap"
import { Colors } from "../../theme/types"

interface Props extends ButtonProps {
  color?: Colors
  size?: ButtonSizes
}

export const Button: React.FC<Props> = ({
  color = "secondary",
  size,
  ...rest
}) => {
  return <ReactStrapButton color={color} size={size} {...rest} />
}

type ButtonSizes = "sm" | "lg"
