import React from "react"
import { Card as ReactstrapCard, CardProps } from "reactstrap"
import { Colors } from "../../theme/types"

interface Props extends CardProps {
  color?: Colors
}

export const Card: React.FC<Props> = ({ color, ...rest }) => {
  return <ReactstrapCard color={color} {...rest} />
}
