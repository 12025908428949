import React from "react"
import { Jumbotron as ReactstrapJumbotron, JumbotronProps } from "reactstrap"
import { Colors } from "../../theme/types"

interface Props extends JumbotronProps {
  color?: Colors
}

export const Jumbotron: React.FC<Props> = props => {
  return <ReactstrapJumbotron {...props} />
}
