import langConfig from "config/i18n"
import { LanguageSettings } from "i18n"
import { PageContext } from "ExtendiApp"
const languages: LanguageSettings = langConfig

export const getMenuLanguages = (lang: string, intPageVersions: PageContext[]) => {
  const langKey = languages[lang].path
  const intVersionThisLang = (intPageVersions || []).find(page => page.locale === lang)
  const hasIntVersion = Boolean(intVersionThisLang && intVersionThisLang.slug) || intVersionThisLang?.pageId === "home"

  const langMenuLink =
    intVersionThisLang && intVersionThisLang.slug
      ? `/${intVersionThisLang.locale.toString()}/${intVersionThisLang.slug}`
      : `/${langKey}`

  return {
    langKey,
    hasIntVersion,
    langMenuLink,
  }
}

export const getLocalizedSubmitUrl = (locale: PageContext["locale"]) => {
  const languageParameter = locale === "en" ? "UK" : locale.toLowerCase()
  const baseUrl = `https://secure.iperbooking.net/be/${languageParameter}/residence-hotel-paradiso-teramo/`
  return baseUrl
}
