type DataLayerPushPayload = Record<string, string | undefined>
type WindowWithDataLayer = Window & {
  dataLayer: {
    push: (data: DataLayerPushPayload) => void
  }
}

export const sendTagManagerEvent = (payload: DataLayerPushPayload) => {
  const extendedWindow = (window as unknown) as WindowWithDataLayer
  if (typeof extendedWindow === "undefined" || !extendedWindow.dataLayer) {
    return
  }

  extendedWindow.dataLayer.push(payload)
}
