import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody } from "src/ui"

interface Props {
  className?: string
  toggleText: string
  modalTitle?: string
  imageName?: string
  imageCaption?: string
}

export const ModalWithToggle: React.FC<Props> = ({
  className,
  toggleText,
  modalTitle,
  imageName,
  imageCaption,
  children,
}) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const modalImage = imageName ? require(`src/assets/imgs/${imageName}`) : null

  return (
    <div className="mt-2">
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          toggle()
        }}
      >
        {toggleText}
      </a>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {modalImage && (
            <div className="modal-body-image mb-4">
              <img src={modalImage} alt={modalTitle} className="img-fluid" />
              <div className="mt-3">{imageCaption}</div>
            </div>
          )}
          <div className="modal-body-content">{children}</div>
        </ModalBody>
      </Modal>
    </div>
  )
}
