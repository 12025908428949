import React from 'react'
import {
  UncontrolledCollapse as ReactstrapUncontrolledCollapse,
  UncontrolledCollapseProps,
} from 'reactstrap'

export const UncontrolledCollapse: React.FC<UncontrolledCollapseProps> = ({
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ReactstrapUncontrolledCollapse {...(rest as any)} />
}
