import { throttle } from "lodash-es"
import { useEffect, useState } from "react"

type ScrollYPosition = number

const isBrowser = typeof document !== `undefined`
const getScrollYPosition = (): ScrollYPosition => {
  return isBrowser ? document.getElementsByTagName("body")[0].scrollTop : 0
}

export const useScrollYPosition = (delayMs: number) => {
  const [scollYPosition, setScrollYPosition] = useState<ScrollYPosition>(getScrollYPosition())

  const handleScroll = () => {
    setScrollYPosition(getScrollYPosition())
  }

  const throttledHandleScroll = throttle(handleScroll, delayMs)

  useEffect(() => {
    document.getElementsByTagName("body")[0].addEventListener("scroll", throttledHandleScroll)
    return () => {
      document.getElementsByTagName("body")[0].addEventListener("scroll", throttledHandleScroll.cancel)
    }
  }, [])

  return scollYPosition
}
