// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-offers-page-tsx": () => import("./../../../src/templates/offersPage.tsx" /* webpackChunkName: "component---src-templates-offers-page-tsx" */),
  "component---src-templates-page-simple-tsx": () => import("./../../../src/templates/pageSimple.tsx" /* webpackChunkName: "component---src-templates-page-simple-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-page-with-map-tsx": () => import("./../../../src/templates/pageWithMap.tsx" /* webpackChunkName: "component---src-templates-page-with-map-tsx" */),
  "component---src-templates-sub-page-alt-tsx": () => import("./../../../src/templates/subPageAlt.tsx" /* webpackChunkName: "component---src-templates-sub-page-alt-tsx" */),
  "component---src-templates-sub-page-tsx": () => import("./../../../src/templates/subPage.tsx" /* webpackChunkName: "component---src-templates-sub-page-tsx" */)
}

