module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.hotelparadiso.it","noTrailingSlash":true,"noQueryString":true,"noHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Residence Hotel Paradiso","short_name":"Residence Hotel Paradiso","description":"Residence Hotel Paradiso - Family and Sport Hotel","start_url":"/it/","lang":"it","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/favicon.png","localize":[{"start_url":"/en/","lang":"en","name":"Residence Hotel Paradiso","short_name":"Residence Hotel Paradiso","description":"Residence Hotel Paradiso - Family and Sport Hotel","theme_color":"en"},{"start_url":"/de/","lang":"de","name":"Residence Hotel Paradiso","short_name":"Residence Hotel Paradiso","description":"Residence Hotel Paradiso - Family and Sport Hotel","theme_color":"de"},{"start_url":"/fr/","lang":"fr","name":"Residence Hotel Paradiso","short_name":"Residence Hotel Paradiso","description":"Residence Hotel Paradiso - Family and Sport Hotel","theme_color":"fr"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f4118f30b8d9cf3de6945cc85cf434ab"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5W284SD","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
