import React, { useState, Fragment } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { Nav, NavItem } from "src/ui"
import langConfig from "config/i18n"
import { LanguageSettings } from "i18n"
import { LocaleContext } from "../layout"
import { PageContext } from "ExtendiApp"
import { getMenuLanguages, getLocalizedSubmitUrl } from "./utils"
import Logo from "src/ui/img/logo.svg"
import LogoMobile from "src/ui/img/logo_m.svg"
import Calculator from "src/ui/img/ico_calculator.svg"
import { Menu } from "./Menu"
import { BurgerMenuButton } from "./BurgerMenuButton"
import { InternalLink } from "../InternalLink"
import useTranslations from "../useTranslations"
import Helmet from "react-helmet"
import { sendTagManagerEvent } from "src/utils/googleTagManager"

interface Props {
  activePageId?: PageContext["pageId"]
}

const Navigation: React.FC<Props> = ({ activePageId }) => {
  const { locale } = React.useContext(LocaleContext)
  const languages: LanguageSettings = langConfig

  const query = graphql`
    query Navigation {
      allSitePage {
        nodes {
          context {
            title
            locale
            slug
            pageId
            menuLevel
            menuOrder
            notShowInMenu
            parentId
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `

  // menu state
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const discoverOffers = useTranslations("discover_offers")
  const requests = useTranslations("get_form")
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          allSitePage: { nodes: pages },
          site: {
            siteMetadata: { siteUrl },
          },
        } = data

        // flatten content
        const allPages: PageContext[] = pages.map(page => page.context)
        const intlPageVersions = allPages.filter(page => page && page.pageId == activePageId)

        return (
          <Fragment>
            <Helmet>
              {Object.keys(languages).map(lang => {
                const { langKey, langMenuLink, hasIntVersion } = getMenuLanguages(lang, intlPageVersions)
                return hasIntVersion ? (
                  <link rel="alternate" key={langKey} hrefLang={langKey} href={siteUrl + langMenuLink} />
                ) : null
              })}
            </Helmet>

            <header id="header">
              <div className="top-panel">
                <div id="logo">
                  <Link to={`/${locale}`} className="navbar-brand">
                    <img alt="Residence Hotel Paradiso" src={Logo} className="d-none d-sm-block" />
                    <img alt="Hotel Paradiso" src={LogoMobile} className="d-block d-sm-none" />
                  </Link>
                </div>
                <div id="lang" className="d-none d-md-flex">
                  <Nav>
                    {Object.keys(languages).map(lang => {
                      const { langKey, langMenuLink } = getMenuLanguages(lang, intlPageVersions)
                      return (
                        <NavItem key={langKey}>
                          <Link
                            to={langMenuLink}
                            hrefLang={langKey}
                            className={`nav-link${langKey === locale ? " current" : ""}`}
                          >
                            {langKey.toUpperCase()}
                          </Link>
                        </NavItem>
                      )
                    })}
                    <NavItem>
                      <InternalLink
                        pageId="richieste-prenotazione/offerte"
                        className="nav-link pulse-link ml-3 d-sm-none d-lg-flex"
                      >
                        {discoverOffers}
                      </InternalLink>
                    </NavItem>
                    <NavItem>
                      <InternalLink
                        pageId="richieste-prenotazione/richiesta"
                        className="nav-link link-btn ml-3 d-sm-none d-lg-flex"
                      >
                        {requests}
                      </InternalLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="fixed-panel">
                  <a
                    href={getLocalizedSubmitUrl(locale)}
                    className="d-none d-md-flex menu-btn"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      sendTagManagerEvent({
                        event: "editaBooking",
                        eventCategory: "Booking",
                        eventAction: "Click",
                        eventLabel: window?.location.href,
                        eventValue: "1",
                        eventNhi: "FALSE",
                        eventFacebook: "Booking Click",
                      })
                    }}
                  >
                    <img alt="" src={Calculator} />
                  </a>
                  <div id="menu-toggle" onClick={() => setMenuIsOpen(true)}>
                    <BurgerMenuButton />
                  </div>
                </div>
              </div>
            </header>
            <Menu
              menuIsOpen={menuIsOpen}
              setMenuIsOpen={setMenuIsOpen}
              allPages={allPages}
              intlPageVersions={intlPageVersions}
              locale={locale}
              languages={languages}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default Navigation
