import React from "react"
import { Link } from "gatsby"
import useInternalLinks from "./useInternalLinks"

interface Props {
  pageId: string
  className?: string
  children: string
}

export const InternalLink: React.FC<Props> = ({ pageId, className, children }) => {
  const linkFromPageId = useInternalLinks(pageId)

  return (
    <Link to={linkFromPageId} className={className}>
      {children}
    </Link>
  )
}
