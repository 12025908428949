import React from "react"
import { Helmet } from "react-helmet"
import { LocaleContext } from "./layout"
import locales from "../../config/i18n"

interface SeoProps {
  title?: string
  description?: string
}

const SEO: React.FC<SeoProps> = ({ title, description }) => {
  const { locale }: any = React.useContext(LocaleContext)
  const meta = locales[locale]

  const seo = {
    title: title || meta.defaultTitle,
    description: description || meta.defaultDescription,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={seo.title}
      // titleTemplate={`%s - ${meta.defaultTitle}`}
    >
      <meta name="description" content={seo.description} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="google-site-verification" content="iPbSstSQLCRETVcCrT2S9-wubsOpq2PvLu6xEg2TUxA" />
    </Helmet>
  )
}

export default SEO
