import React from "react"

interface Props {
  onClick: () => void
}

export const CrossMenuButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="menu-close-button" onClick={onClick}>
      <div id="cross">
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
