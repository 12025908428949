import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"

const useTranslations = (keyToTranslate: string) => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale }: any = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  if (keyToTranslate) {
    return translations && translations[keyToTranslate] ? translations[keyToTranslate] : `%${keyToTranslate}%`
  }

  return translations
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            arrival
            departure
            adults
            _children
            legal_notes
            privacy
            cookies
            get_a_quote
            call_us
            get_offers
            italy
            more
            empty_select_option
            firstName
            lastName
            address
            postalCode
            city
            province
            email
            numberOfAdults
            numberOfChildren
            arrivalDate
            departureDate
            accommodation
            treatment
            how_do_you_know_us
            extra_notes
            request_type
            offer
            booking
            privacy_top_line
            privacy_bottom_line
            submit_button_text
            required_field
            invalid_format
            ageChildren_0
            ageChildren_1
            ageChildren_2
            ageChildren_3
            ageChildren_4
            ageChildren_5
            ageChildren_6
            ageChildren_7
            request_sent_thankyou
            submit_error_messagge
            your_data
            your_holiday
            extra_info
            cookie_content_text
            cookies_accept
            spotted_on
            interested
            discover_offers
            get_form
            where
            when
            site_alert_summer_title
            site_alert_summer_text
            site_alert_summer_button
            full_board
            half_board
            take_away_meals
            b_b
            without_pension
            single_room
            double_room
            triple_room
            quadruple_room
            apartment_type_A
            apartment_type_B
            already_guest
            from_friends
            from_research
            another_website
            newspaper
          }
        }
      }
    }
  }
`
