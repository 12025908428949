import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"

const useInternalLinks = (pageId: string) => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale }: any = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { allInternalUrls } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified: {
    pageId: string
    locale: string
    path: string
  }[] = (allInternalUrls || []).edges.map(({ node }) => {
    return {
      pageId: (node && node.context && node.context.pageId) || null,
      locale: (node && node.context && node.context.locale) || null,
      path: (node && node.path) || null,
    }
  })
  const link = simplified.find(item => item.pageId === pageId && item.locale === locale)
  return (link && link.path) || "/"
}

export default useInternalLinks

const query = graphql`
  query useInternalLinks {
    allInternalUrls: allSitePage {
      edges {
        node {
          context {
            pageId
            locale
          }
          path
        }
      }
    }
  }
`
