import React from "react"

export const BurgerMenuButton: React.FC = () => {
  return (
    <div id="hamburger">
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
